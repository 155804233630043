<template>
  <div class="star-rating">
    <span
      v-for="star in stars"
      :key="star"
      class="star"
      :class="{ filled: star <= rating }"
      @click="setRating(star)"
      @mouseover="highlight(star)"
      @mouseleave="resetHighlight"
    >
      ★
    </span>
  </div>
</template>

<script>
export default {
  name: "StarRating",
  props: {
    rating: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      stars: [1, 2, 3, 4, 5],
      temporaryRating: this.rating,
    };
  },
  methods: {
    setRating(star) {
      this.$emit("rating-selected", star);
    },
    highlight(star) {
      this.temporaryRating = star;
    },
    resetHighlight() {
      this.temporaryRating = this.rating;
    },
  },
  computed: {
    currentRating() {
      return this.temporaryRating;
    },
  },
};
</script>

<style scoped>
.star-rating {
  display: flex;
  align-items: center;
}
.star {
  cursor: pointer;
  font-size: 2em;
  color: #ccc;
  transition: color 0.2s;
}
.star.filled {
  color: #ffd700;
}
</style>
