// GlobalProgress.vue
<template>
  <div v-if="isLoading" class="progress-overlay">
    <div class="progress-content">
      <p>{{ progressMessage }}</p>
      <progress :value="progress" max="100" class="styled-progress"></progress>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    progress() {
      return this.$store.state.progress;
    },
    progressMessage() {
      return this.$store.state.progressMessage;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
};
</script>

<style>
.progress-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.progress-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.styled-progress {
  width: 100%;
  height: 20px;
  -webkit-appearance: none;
  appearance: none;
}

.styled-progress::-webkit-progress-bar {
  background-color: #e0e0e0;
  border-radius: 10px;
}

.styled-progress::-webkit-progress-value {
  background-color: #4caf50; /* Green color for progress value */
  border-radius: 10px;
}

.styled-progress::-moz-progress-bar {
  background-color: #4caf50; /* Green color for progress value */
  border-radius: 10px;
}
</style>
