<template>
  <div
    id="cesiumContainer"
    ref="cesiumContainer"
    style="height: 100vh; width: 100%"
  >
    <button
      class="btn btn-primary btn-large fly-button"
      @click="flyAlongPolyline"
    >
      Fly Along Trail
    </button>
    <button class="stop-button" @click="stopFlight">Stop</button>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import db from "@/db";

import start from "@/assets/start.png";
import stop from "@/assets/stop.png";
import pinRed from "@/assets/location-dot-solid-Red.svg";
import pinBlue from "@/assets/location-dot-solid-Blue.svg";

export default {
  name: "CesiumMap",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const cesiumContainer = ref(null);
    let viewer = null;
    let polylinePositions = [];
    let flightInProgress = false;

    onMounted(async () => {
      if (typeof Cesium !== "undefined") {
        // Set Cesium Ion default access token
        Cesium.Ion.defaultAccessToken =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJjMzUwM2I1Zi1lMTg2LTRkYjYtOTgyNy1lZDBiMmUxNTE1YWIiLCJpZCI6MTk5ODIyLCJpYXQiOjE3MDk2NTI2NTB9.1JyPgVYYsQ8DK7XcWIYShkPISi0TAOwzRQt8auUGJ78";

        // Initialize the Cesium Viewer
        viewer = new Cesium.Viewer(cesiumContainer.value, {
          terrainProvider: Cesium.createWorldTerrain(),
          imageryProvider: new Cesium.IonImageryProvider({ assetId: 2 }), // Bing Maps Aerial imagery
          mapStyle: Cesium.BingMapsStyle.AERIAL,
        });

        // Log viewer and provider details
        //console.log("Cesium viewer initialized:", viewer);
        // console.log("Imagery provider:", viewer.imageryLayers);

        // Load card data
        const card = await db.cards.get({ CardId: parseInt(props.id) });
        if (card) {
          addPolylineAndMarkers(card);
          addPOIMarkers(card);
          // Ensure all entities are added before zooming
          setTimeout(() => {
            zoomToPolyline();
          }, 1000);
        }

        // Start location tracking
        //startLocationWatch();
      } else {
        console.error("Cesium is not defined");
      }
    });

    onUnmounted(() => {
      if (viewer) {
        viewer.destroy();
      }
    });

    async function addPolylineAndMarkers(card) {
      const { Polyline } = card;

      if (Polyline && Polyline.length > 0) {
        polylinePositions = Polyline.map(point =>
          Cesium.Cartesian3.fromDegrees(
            parseFloat(point.lng),
            parseFloat(point.lat)
          )
        );

        //console.log("Polyline positions before clamping:", positions);

        // Clamp polyline to ground
        const clampedPositions = await Cesium.sampleTerrainMostDetailed(
          viewer.terrainProvider,
          polylinePositions
        );

        //console.log("Clamped polyline positions:", clampedPositions);

        viewer.entities.add({
          polyline: {
            positions: clampedPositions,
            width: 5,
            material: Cesium.Color.BLUE,
            clampToGround: true,
          },
        });

        //viewer.zoomTo(viewer.entities);

        // Add start marker
        viewer.entities.add({
          position: clampedPositions[0],
          billboard: {
            image: start, // Replace with the correct path to your start icon
            width: 30,
            height: 30,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          },
        });

        // Add end marker
        viewer.entities.add({
          position: clampedPositions[clampedPositions.length - 1],
          billboard: {
            image: stop, // Replace with the correct path to your stop icon
            width: 30,
            height: 30,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          },
        });
      } else {
        console.warn("No polyline data available");
      }
    }

    async function fetchImageUrl(imageId) {
      try {
        const imageRecord = await db.images.get(imageId);
        return imageRecord ? URL.createObjectURL(imageRecord.blob) : "";
      } catch (error) {
        console.error("Failed to fetch image:", error);
        return "";
      }
    }

    async function addPOIMarkers(card) {
      const { POI } = card;

      if (POI && POI.length > 0) {
        console.log("POI data:", POI);

        const positions = POI.map(poi =>
          Cesium.Cartesian3.fromDegrees(
            parseFloat(poi.Longitude),
            parseFloat(poi.Latitude)
          )
        );

        // Clamp POI markers to ground
        const clampedPositions = await Cesium.sampleTerrainMostDetailed(
          viewer.terrainProvider,
          positions
        );

        for (const [index, poi] of POI.entries()) {
          const position = clampedPositions[index];
          const pinColor =
            poi.TypeId === "1" ? Cesium.Color.RED : Cesium.Color.BLUE;
          const imageUrl = await fetchImageUrl(poi.imageId); // Fetch the image URL dynamically

          const poiEntity = viewer.entities.add({
            position,
            billboard: {
              image: poi.TypeId === "1" ? pinRed : pinBlue, // Replace with correct paths
              width: 30,
              height: 30,
              heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            },
            description: `
              <h3>${poi.Title}</h3>
              <p>${poi.Body}</p>
              <img src="${imageUrl}" alt="${poi.Title}" style="width: 100%;" />
            `,
          });

          //console.log("Added POI entity:", poiEntity);
        }
      } else {
        console.warn("No POI data available");
      }
    }

    function startLocationWatch() {
      if ("geolocation" in navigator) {
        navigator.geolocation.watchPosition(
          updateLocation,
          handleLocationError,
          { enableHighAccuracy: true }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    }

    function zoomToPolyline() {
      const test = viewer.entities.values;
      const polylineEntities = viewer.entities.values.filter(
        entity => entity.polyline
      );
      console.log("Polyline entities:", polylineEntities);
      if (polylineEntities.length > 0) {
        viewer.flyTo(polylineEntities);
      } else {
        console.warn("No polyline entities found to zoom to.");
      }
    }

    function updateLocation(position) {
      const { latitude, longitude } = position.coords;
      const newLatLng = Cesium.Cartesian3.fromDegrees(longitude, latitude);

      viewer.entities.add({
        position: newLatLng,
        point: {
          pixelSize: 10,
          color: Cesium.Color.YELLOW,
        },
      });

      //viewer.camera.flyTo({
      // destination: newLatLng,
      //});
    }

    function handleLocationError(error) {
      console.error("Location error:", error);
    }

    function stopFlight() {
      flightInProgress = false;
      viewer.clock.shouldAnimate = false;
      viewer.camera.cancelFlight();
    }

    async function flyAlongPolyline() {
      if (polylinePositions.length > 0) {
        flightInProgress = true;

        const duration = 120; // Total duration for the entire flight in seconds
        const height = 150; // Set the height for each point
        const heightOffset = 20; // Height above ground level
        const startTime = Cesium.JulianDate.now();
        const endTime = Cesium.JulianDate.addSeconds(
          startTime,
          duration,
          new Cesium.JulianDate()
        );
        const sampledPosition = new Cesium.SampledPositionProperty();
        let lastHeading = null;

        // Convert polyline positions to Cartographic for clamping
        const cartographicPositions = polylinePositions.map(position =>
          Cesium.Cartographic.fromCartesian(position)
        );

        // Clamp positions to ground and add height offset
        const clampedCartographics = await Cesium.sampleTerrainMostDetailed(
          viewer.terrainProvider,
          cartographicPositions
        );

        clampedCartographics.forEach((cartographic, index) => {
          const adjustedPosition = Cesium.Cartesian3.fromDegrees(
            Cesium.Math.toDegrees(cartographic.longitude),
            Cesium.Math.toDegrees(cartographic.latitude),
            cartographic.height + heightOffset // Add height offset above ground level
          );
          const time = Cesium.JulianDate.addSeconds(
            startTime,
            (index / clampedCartographics.length) * duration,
            new Cesium.JulianDate()
          );
          sampledPosition.addSample(time, adjustedPosition);
        });

        // Create an entity with the sampled position and velocity orientation properties
        const entity = viewer.entities.add({
          position: sampledPosition,
          orientation: new Cesium.VelocityOrientationProperty(sampledPosition),
        });

        viewer.clock.startTime = startTime.clone();
        viewer.clock.stopTime = endTime.clone();
        viewer.clock.currentTime = startTime.clone();
        viewer.clock.clockRange = Cesium.ClockRange.CLAMPED;
        viewer.clock.multiplier =
          duration /
          Cesium.JulianDate.secondsDifference(
            viewer.clock.stopTime,
            viewer.clock.startTime
          );
        viewer.clock.shouldAnimate = true;

        viewer.camera.setView({
          destination: sampledPosition.getValue(startTime),
          orientation: {
            heading: Cesium.Math.toRadians(0.0),
            pitch: Cesium.Math.toRadians(-5.0),
            roll: 0.0,
          },
        });

        function followPath(scene, time) {
          if (!flightInProgress) {
            viewer.scene.postUpdate.removeEventListener(followPath);
            return;
          }

          const currentPosition = entity.position.getValue(time);
          const nextPosition = sampledPosition.getValue(
            Cesium.JulianDate.addSeconds(time, 1, new Cesium.JulianDate())
          );

          if (currentPosition && nextPosition) {
            const currentCartographic =
              Cesium.Cartographic.fromCartesian(currentPosition);
            const nextCartographic =
              Cesium.Cartographic.fromCartesian(nextPosition);

            let heading = calculateHeading(
              currentCartographic,
              nextCartographic
            );

            if (lastHeading !== null) {
              heading = Cesium.Math.lerp(lastHeading, heading, 0.05);
            }

            lastHeading = heading;

            const hpr = new Cesium.HeadingPitchRoll(
              Cesium.Math.toRadians(heading),
              Cesium.Math.toRadians(-5.0),
              0.0
            );

            viewer.camera.setView({
              destination: currentPosition,
              orientation: {
                heading: hpr.heading,
                pitch: hpr.pitch,
                roll: hpr.roll,
              },
            });
          }
        }

        viewer.scene.postUpdate.addEventListener(followPath);

        function calculateHeading(startCartographic, endCartographic) {
          const startLon = startCartographic.longitude;
          const startLat = startCartographic.latitude;
          const endLon = endCartographic.longitude;
          const endLat = endCartographic.latitude;

          const dLon = endLon - startLon;
          const y = Math.sin(dLon) * Math.cos(endLat);
          const x =
            Math.cos(startLat) * Math.sin(endLat) -
            Math.sin(startLat) * Math.cos(endLat) * Math.cos(dLon);
          const heading = Math.atan2(y, x);

          return Cesium.Math.toDegrees(heading);
        }
      } else {
        console.warn("No polyline positions available to fly along");
      }
    }

    return {
      cesiumContainer,
      flyAlongPolyline,
      stopFlight,
    };
  },
};
</script>

<style>
#cesiumContainer {
  height: 80vh !important;
  width: 100%;
}

/* Custom styles for Cesium InfoBox */
.cesium-infoBox {
  width: 800px !important; /* Increase the width */
  height: 700px !important; /* Increase the height */
}

.cesium-infoBox .cesium-infoBox-description {
  max-height: 700px !important; /* Adjust the height to fit within the InfoBox */
  overflow: auto !important; /* Ensure content is scrollable if it overflows */
}

.cesium-infoBox .cesium-infoBox-iframe {
  height: 700px !important; /* Ensure iframe height fits within the InfoBox */
}

.cesium-infoBox .cesium-infoBox-description img {
  width: 100% !important; /* Ensure images take up 100% of the width */
  height: auto !important; /* Maintain aspect ratio */
  display: block !important; /* Remove any inline styling that might affect the display */
  margin: 0 !important; /* Remove any margins */
  padding: 0 !important; /* Remove any padding */
  border: none !important; /* Remove any borders */
}

.cesium-infoBox.cesium-infoBox-visible {
  height: 700px !important;
}

iframe.cesium-infoBox-iframe {
  height: 70px !important;
}

.fly-button,
.stop-button {
  position: absolute;
  top: 150px;
  left: 10px;
  z-index: 1000;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.fly-button:hover {
  background-color: #0056b3;
}

.stop-button {
  left: 160px;
}
</style>
