<!-- src/layouts/MainLayout.vue -->
<template>
  <!-- Topbar Start -->
  <div class="container-fluid bg-light pt-3 d-none d-lg-block">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 text-center text-lg-left mb-2 mb-lg-0">
          <div class="d-inline-flex align-items-center">
            <p><i class="fa fa-envelope mr-2"></i>{{ header.Email }}</p>
            <p class="text-body px-3">|</p>
            <p><i class="fa fa-phone-alt mr-2"></i> {{ header.Phone }}</p>
          </div>
        </div>
        <div class="col-lg-6 text-center text-lg-right">
          <div class="d-inline-flex align-items-center">
            <!-- Facebook -->
            <a
              v-if="header.Facebook"
              class="text-primary px-3"
              :href="header.Facebook"
              target="_blank"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
            <!-- Twitter -->
            <a
              v-if="header.Twitter"
              class="text-primary px-3"
              :href="header.Twitter"
              target="_blank"
            >
              <i class="fab fa-twitter"></i>
            </a>
            <!-- LinkedIn -->
            <a
              v-if="header.LinkedIn"
              class="text-primary px-3"
              :href="header.LinkedIn"
              target="_blank"
            >
              <i class="fab fa-linkedin-in"></i>
            </a>
            <!-- Instagram -->
            <a
              v-if="header.Instagram"
              class="text-primary px-3"
              :href="header.Instagram"
              target="_blank"
            >
              <i class="fab fa-instagram"></i>
            </a>
            <!-- Youtube -->
            <a
              v-if="header.Youtube"
              class="text-primary px-3"
              :href="header.Youtube"
              target="_blank"
            >
              <i class="fab fa-youtube"></i>
            </a>

            <a
              v-if="isHomePage"
              class="text-primary pl-3 bin"
              href=""
              @click="deleteDatabase"
              style="margin-left: 8px"
            >
              <i class="fa fa-trash"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Topbar End -->

  <!-- Navbar Start -->
  <div class="container-fluid position-relative nav-bar p-0">
    <div class="container-lg position-relative p-0 px-lg-3" style="z-index: 9">
      <nav
        class="navbar navbar-expand-lg bg-light navbar-light shadow-lg py-3 py-lg-0 pl-lg-5"
      >
        <div
          class="navbar-nav ml-auto py-0 d-flex flex-wrap align-items-center"
        >
          <router-link to="/" class="nav-item nav-link"
            ><i class="fas fa-home" style="color: green"></i> Home</router-link
          >
          <router-link to="/walks" class="nav-item nav-link"
            ><i class="fa fa-shoe-prints"></i> All Trails</router-link
          >
          <router-link to="/completedWalks" class="nav-item nav-link"
            ><i class="fas fa-check" style="color: #04f104"></i> Trails
            Done</router-link
          >
          <div
            class="nav-item d-flex align-items-center nav-link notification-item"
          >
            <span class="ml-2">Notifications</span>
            <label class="switch" style="margin-left: 8px">
              <input
                type="checkbox"
                v-model="notificationsEnabled"
                @change="toggleNotifications"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <!-- Navbar End -->
</template>

<script>
import { mapState } from "vuex";
import db from "@/db";
export default {
  name: "Header",
  components: {},
  created() {
    // Load the saved state from localStorage when the component is created
    const savedState = localStorage.getItem("notificationsEnabled");
    if (savedState !== null) {
      this.notificationsEnabled = JSON.parse(savedState);
    }
  },
  data() {
    return {
      tenantId: "", // Initial state
      header: {},
      notificationsEnabled: false,
    };
  },
  computed: {
    ...mapState(["apiUrl", "customerId"]),
    isHomePage() {
      return this.$route.name === "home"; // Ensure 'home' is the name of your homepage route
    },
  },
  async mounted() {
    try {
      // Fetch General Header data
      this.tenantId = localStorage.getItem("tenantId");

      if (!this.tenantId) {
        throw new Error("Tenant ID not found in localStorage.");
      }

      var headerArray = await db.header
        .where("CustomerId")
        .equals(parseInt(this.tenantId))
        .toArray();

      if (headerArray.length > 0) {
        this.header = headerArray[0];
      } else {
        console.warn("No header data found for the given tenant ID.");
      }
    } catch (error) {
      console.error("Error fetching header data:", error);
    }
  },
  methods: {
    deleteDatabase() {
      const dbName = "AppDatabase";

      const request = indexedDB.deleteDatabase(dbName);

      request.onsuccess = () => {
        console.log("Database deleted successfully");
        this.redirectHome();
      };

      request.onerror = event => {
        console.error("Error deleting database:", event);
      };

      request.onblocked = () => {
        console.warn("Database deletion blocked");
      };
    },
    redirectHome() {
      this.$router.push({ name: "home" });
    },
    toggleNotifications() {
      // Save the state to localStorage whenever it changes
      localStorage.setItem(
        "notificationsEnabled",
        JSON.stringify(this.notificationsEnabled)
      );

      if (this.notificationsEnabled) {
        this.askNotificationPermission();
        console.log("Subscribed..");
      } else {
        this.unsubscribeUserFromPush();
        console.log("UnSubscribed..");
      }
    },
    askNotificationPermission() {
      if ("Notification" in window && "serviceWorker" in navigator) {
        Notification.requestPermission().then(permission => {
          if (permission === "granted") {
            console.log("Notification permission granted.");
            this.subscribeUserToPush();
          } else {
            console.log("Notification permission denied.");
          }
        });
      } else {
        console.log("Notifications or Service Workers are not supported.");
      }
    },
    subscribeUserToPush() {
      const urlBase64ToUint8Array = base64String => {
        const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding)
          .replace(/-/g, "+")
          .replace(/_/g, "/");
        const rawData = window.atob(base64);
        return new Uint8Array(
          rawData.split("").map(char => char.charCodeAt(0))
        );
      };

      navigator.serviceWorker.ready.then(registration => {
        const applicationServerKey = urlBase64ToUint8Array(
          "BF4idPaJxVZGQahiE16Jw77o9Yg-1f9kTNDB-nKcmTNuHyQx1dhEWPDRhXsktijRbFYAOPTJk8zX7Awtck5B9kc"
        );
        registration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey,
          })
          .then(subscription => {
            console.log("User is subscribed:", subscription);
            console.log(
              "Subscription object keys:",
              subscription.toJSON().keys
            );
            console.log("Full subscription object:", subscription);

            // Manually construct the subscription object
            const pushSubscription = {
              endpoint: subscription.endpoint,
              expirationTime: subscription.expirationTime,
              keys: {
                p256dh: subscription.toJSON().keys.p256dh,
                auth: subscription.toJSON().keys.auth,
              },
            };

            // Send subscription to your server to store it
            this.sendSubscriptionToServer(pushSubscription);
          })
          .catch(error => {
            console.error("Failed to subscribe the user: ", error);
          });
      });
    },
    unsubscribeUserFromPush() {
      navigator.serviceWorker.ready.then(registration => {
        registration.pushManager
          .getSubscription()
          .then(subscription => {
            if (subscription) {
              const pushSubscription = {
                endpoint: subscription.endpoint,
                expirationTime: subscription.expirationTime,
                keys: {
                  p256dh: subscription.toJSON().keys.p256dh,
                  auth: subscription.toJSON().keys.auth,
                },
              };

              fetch(this.apiUrl + "unsubscribe", {
                method: "POST",
                body: JSON.stringify(pushSubscription),
                headers: {
                  "Content-Type": "application/json",
                  CustomerId: this.customerId,
                  Authorization: `Basic ${btoa("Ian:Ennistymon1!")}`,
                },
              })
                .then(response => {
                  if (!response.ok) {
                    throw new Error("Bad status code from server.");
                  }
                  return response.json();
                })
                .then(responseData => {
                  if (!(responseData && responseData.success)) {
                    throw new Error("Bad response from server.");
                  }
                  console.log(
                    "Successfully unsubscribed from push notifications."
                  );
                })
                .catch(error => {
                  console.error("Error unsubscribing:", error);
                });

              subscription
                .unsubscribe()
                .then(() => {
                  console.log(
                    "Successfully unsubscribed from push notifications."
                  );
                })
                .catch(error => {
                  console.error(
                    "Failed to unsubscribe from push notifications:",
                    error
                  );
                });
            } else {
              console.log("No existing subscription found.");
            }
          })
          .catch(error => {
            console.error("Error while getting subscription:", error);
          });
      });
    },
    sendSubscriptionToServer(subscription) {
      const base64Credentials = btoa("Ian:Ennistymon1!");

      console.log("Subscription object to be sent:", subscription);

      const subscriptionData = {
        Endpoint: subscription.endpoint,
        P256DH: subscription.keys.p256dh,
        Auth: subscription.keys.auth,
      };

      console.log("Subscription data to be sent:", subscriptionData);

      // Implement sending the subscription object to your server
      fetch(this.apiUrl + "subscribe", {
        method: "POST",
        body: JSON.stringify(subscriptionData),
        headers: {
          "Content-Type": "application/json",
          CustomerId: this.customerId,
          Authorization: `Basic ${base64Credentials}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("Bad status code from server.");
          }
          return response.json();
        })
        .then(responseData => {
          if (!(responseData && responseData.success)) {
            throw new Error("Bad response from server.");
          }
        })
        .catch(error => {
          console.error("Error sending subscription to server:", error);
        });
    },
  },
};
</script>
<style scoped>
.nav-bar {
  width: 100%;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-nav {
  display: flex;
  flex-direction: row;
}

.nav-item {
  margin-left: 3px;
}

/* Responsive styling for small screens */
@media (max-width: 768px) {
  .navbar-nav {
    font-size: 16px; /* Slightly smaller font size */
    flex-wrap: wrap; /* Allow items to wrap if they exceed screen width */
    justify-content: center; /* Center the items on the first row */
  }

  .nav-item {
    margin-left: 3px;
    margin-bottom: 5px; /* Add space between wrapped items */
  }

  .nav-item.d-flex.align-items-center.nav-link.notification-item {
    flex-basis: 100%; /* Force the notification item to take up full width */
    text-align: center; /* Center the notification item horizontally */
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }

  .switch {
    transform: scale(0.8); /* Reduce switch size slightly */
    margin-left: 5px;
  }

  /* Ensure the switch and text stay together and fit on screen */
  .nav-item .ml-2 {
    white-space: nowrap; /* Prevent text wrapping inside the label */
  }
}

@media (max-width: 600px) {
  .navbar-nav {
    font-size: 13px; /* Further reduce font size for very small screens */
  }

  .nav-item {
    margin-left: 3px;
  }

  .switch {
    transform: scale(0.75); /* Further reduce switch size */
  }
}

.header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;
}

.bin {
  visibility: visible;
}
.nav-item.nav-link {
  display: flex;
  align-items: center;
}

.logo {
  width: 80px !important;
  margin-bottom: 0; /* Remove the bottom margin */
  margin-right: 10px; /* Add some space between the logo and text */
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
  margin-bottom: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 25px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 2.5px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(25px);
}

/* Adding the "On" text inside the switch */
input:checked + .slider::after {
  content: "On";
  color: white;
  position: absolute;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 10px;
  font-weight: bold;
}

/* "Off" text when switch is inactive */
.slider::after {
  content: "Off";
  color: rgb(255, 0, 0);
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 10px;
  font-weight: bold;
}

/* ICONS */
/* Style the icons within the navbar */
.nav-item i {
  font-size: 18px; /* Increase the size of the icons */
  color: #007bff; /* Add a color to the icons (e.g., blue) */
  padding-right: 8px; /* Add some space between the icon and the text */
  transition: color 0.3s; /* Smooth color transition on hover */
}

/* Change color on hover */
.nav-item:hover i {
  color: #0056b3; /* Darker shade of blue on hover */
}

/* Adjust the alignment and spacing of the nav items */
.nav-item.nav-link {
  display: flex;
  align-items: center;
  padding: 10px 10px; /* Adjust the padding for better spacing */
}

/* Additional styling for better look */
.nav-item .nav-link i {
  margin-right: 8px;
}

/* Adjust notification switch container */
.nav-item.d-flex.align-items-center.nav-link {
  padding-left: 20px; /* Ensure spacing consistency with other items */
}
</style>
