<template>
  <button class="btn btn-primary back-button" @click="goBack">← Back</button>

  <!-- Blog Start -->
  <div class="container-fluid py-5">
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-8">
          <!-- Blog Detail Start -->
          <div class="pb-3">
            <div class="blog-item">
              <div class="position-relative">
                <ImageComponent
                  v-if="poiItem.imageId"
                  :imageId="poiItem.imageId"
                  class="img-fluid w-100"
                ></ImageComponent>
              </div>
            </div>
            <div class="bg-white mb-3" style="padding: 30px">
              <h2 class="mb-3">
                {{ poiItem.Title }}
              </h2>
              <h5 class="mb-3 text-primary text-uppercase">
                {{ poiItem.SubTitle }}
              </h5>
              <p>
                {{ poiItem.Body }}
              </p>
            </div>
          </div>
          <!-- Blog Detail End -->
        </div>

        <div class="col-lg-4 mt-5 mt-lg-0">
          <!-- Author Bio -->
          <div class="d-flex flex-column text-center bg-white mb-5 py-5 px-4">
            <img
              src="/img/wicklow-1.jpg"
              class="img-fluid mx-auto mb-3"
              style="width: 100px"
            />
            <h3 class="text-primary mb-3">{{ poiItem.Title }}</h3>
            <p>
              {{ poiItem.SubTitle }}
            </p>
            <div class="d-flex justify-content-center">
              <a class="text-primary px-2" href="">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a class="text-primary px-2" href="">
                <i class="fab fa-twitter"></i>
              </a>
              <a class="text-primary px-2" href="">
                <i class="fab fa-linkedin-in"></i>
              </a>
              <a class="text-primary px-2" href="">
                <i class="fab fa-instagram"></i>
              </a>
              <a class="text-primary px-2" href="">
                <i class="fab fa-youtube"></i>
              </a>
            </div>
          </div>

          <div class="d-flex flex-column text-center bg-white mb-5 py-5 px-4">
            <h5 v-if="poiItem.Website">
              <a :href="poiItem.Website" target="_blank">Website</a>
            </h5>
            <h5 v-if="poiItem.DirectionsLink">
              <a :href="poiItem.DirectionsLink" target="_blank">Directions</a>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Blog End -->
</template>
<script>
import db from "@/db";
import LeafletMap from "@/components/LeafletMap.vue";
import ImageComponent from "@/components/ImageComponent.vue";

export default {
  components: {
    LeafletMap,
    ImageComponent,
  },
  data() {
    return {
      poiItem: { Image: "" },
    };
  },
  async mounted() {
    try {
      // Fetch card data based on the CardId parameter
      const Id = parseInt(this.$route.params.Id); // Convert to integer if necessary

      //only fetch the Gallery data rather than the whl;e card
      var poiData = await db.pois.where("MarkerId").equals(Id).toArray();

      this.poiItem = poiData[0];
    } catch (error) {
      console.error("Error fetching card data:", error);
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Go back to the previous page
    },
  },
};
</script>
