<template>
  <link href="lib/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
  <div>
    <div v-if="showInstallPopup" class="install-popup">
      <p>
        To install this as an app on your device, click below OR click the icon
        on your browser nav bar:
      </p>
      <button
        @click="promptForInstall"
        v-if="deferredPrompt"
        class="install-button"
      >
        Install App
      </button>
      <p v-else>
        Click the install icon (plus symbol) in your browser's address bar to
        install the app.
      </p>
      <button
        @click="dismissInstallPopup"
        class="close-popup-button"
        style="margin-left: 10px"
      >
        Hide
      </button>
    </div>
  </div>

  <div class="container-fluid p-0">
    <div id="header-carousel" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            v-if="header.MainImageCarousel"
            class="w-100"
            :src="header.MainImageCarousel"
            alt="Main Image Carousel"
          />
          <div
            class="carousel-caption d-flex flex-column align-items-center justify-content-center"
          >
            <div class="p-3" style="max-width: 900px">
              <!-- <h1 class="display-3 text-white mb-md-4">
                {{ header.Subtitle }}
              </h1>-->
              <div>
                <img
                  v-if="header.Logo"
                  class="logo"
                  :src="header.Logo"
                  alt="Main Image Logo Image"
                />
              </div>
              <router-link to="/walks" class="btn btn-primary btn-large btnMob">
                Trails
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- News Section Start -->
  <div class="container-fluid py-5" style="padding-top: 10px !important">
    <div class="text-center">
      <h6
        class="text-primary text-uppercase"
        style="letter-spacing: 5px; margin-top: 20px"
      >
        Latest
      </h6>
      <h1>What's new</h1>
    </div>
    <div class="text-center pb-4">
      <div class="row">
        <div
          v-for="(update, index) in updates"
          :key="index"
          class="col-lg-4 col-md-4 col-sm-6 pb-2"
          style="padding: 20px"
        >
          <div class="bg-white mb-4 p-2 h-100">
            <img
              :src="update.Image"
              :alt="update.Title"
              class="w-100 mb-2"
              style="
                max-height: 300px;
                height: 300px;
                width: auto;
                object-fit: cover;
              "
            />
            <div class="text-center py-4 update-card">
              <h5 class="text-truncate update-title">{{ update.Title }}</h5>
              <p class="text-muted update-description">
                {{ update.News }}
              </p>
              <p class="text-muted small update-date">
                {{ new Date(update.PostDate).toLocaleDateString() }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- News Section End -->

  <!-- Blog Posts Start

  <div
    v-if="isBlogUrlValid"
    class="container-fluid py-5"
    style="padding-top: 10px !important"
  >
    <div class="text-center">
      <div class="text-center">
        <h6
          class="text-primary text-uppercase"
          style="letter-spacing: 5px; margin-top: 20px"
        >
          Latest
        </h6>
        <h1>What's new</h1>
      </div>
      <div class="text-center pb-4">
        <div class="row">
          <div
            v-for="(post, index) in posts"
            :key="index"
            class="col-lg-4 col-md-4 col-sm-6 pb-2"
            style="padding: 20px"
          >
            <a :href="post.link" target="_blank" class="d-block">
              <div class="bg-white mb-4 p-2 h-100">
                <img :src="post.image" :alt="post.title" class="w-100 mb-2" />
                <div class="text-center py-4">
                  <h5 class="text-truncate">{{ post.title }}</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
   -->
  <!-- Blog Posts End -->

  <!-- Registration Start -->
  <div class="container-fluid bg-registration py-5" style="margin: 20px 0">
    <div class="container py-5">
      <div class="row align-items-center">
        <div class="col-lg-7 mb-5 mb-lg-0">
          <div class="mb-4">
            <h6 class="text-primary text-uppercase" style="letter-spacing: 5px">
              {{ header.Title }}
            </h6>
          </div>
          <p class="text-white">
            Get in touch and get an Information pack with an overview of our
            walks and trails and much more
          </p>
        </div>
        <div class="col-lg-5">
          <div class="card border-0">
            <div class="card-header bg-primary text-center p-4">
              <h1 class="text-white m-0">Sign Up</h1>
            </div>
            <div class="card-body rounded-bottom bg-white p-5">
              <form>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control p-4"
                    placeholder="Your name"
                    required="required"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control p-4"
                    placeholder="Your email"
                    required="required"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control p-4"
                    placeholder="Message"
                    required="required"
                  />
                </div>
                <div>
                  <button class="btn btn-primary btn-block py-3" type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Registration End -->

  <!-- <button @click="deleteDatabase" class="btn btn-default">
    Delete Database
  </button>
  -->
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import db from "@/db";
import { mapState, mapMutations } from "vuex";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = swiper => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  data() {
    return {
      tenantId: "", // Initial state
      posts: [],
      header: {},
      updates: [],
      showInstallButton: false,
      showDesktopPopup: false,
    };
  },
  computed: {
    ...mapState(["deferredPrompt", "showInstallPopup"]),
    isBlogUrlValid() {
      return this.header.BlogURL && this.header.BlogURL.trim() !== "";
    },
  },
  created() {
    // Fetch the tenantId from local storage when the component is created
    this.tenantId = localStorage.getItem("tenantId");
  },
  async mounted() {
    this.showInstallButton = this.$showInstallButton;
    this.showDesktopPopup = this.$showDesktopPopup;

    try {
      // Fetch General Header data

      if (!this.tenantId) {
        throw new Error("Tenant ID not found in localStorage.");
      }

      var headerArray = await db.header
        .where("CustomerId")
        .equals(parseInt(this.tenantId))
        .toArray();

      if (headerArray.length > 0) {
        this.header = headerArray[0];
        console.log("Header:", this.header); // Log the header object

        // Log the specific value of BlogUrl
        console.log("header.BlogUrl:", this.header.BlogURL);

        // Log the value of isBlogUrlValid after header is set
        console.log("isBlogUrlValid:", this.isBlogUrlValid);
      } else {
        console.warn("No header data found for the given tenant ID.");
      }
    } catch (error) {
      console.error("Error fetching header data:", error);
    }

    if (this.header.BlogURL && this.header.BlogURL.trim() !== "") {
      this.fetchRSS(this.header.BlogURL);
    }

    this.fetchUpdates();
  },
  methods: {
    ...mapMutations(["setShowInstallPopup"]),
    promptForInstall() {
      // Check if the user is on iOS
      const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

      if (isIOS) {
        // Display a custom message for iOS users, guiding them to install the PWA manually
        alert(
          "To install this app on your iPhone or iPad, tap the share icon at the bottom of Safari and select 'Add to Home Screen'."
        );
        console.log(
          "No install prompt available for iOS, user must manually add to home screen."
        );
      } else if (this.deferredPrompt) {
        // For Android/Desktop users, continue with the deferred prompt
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then(choiceResult => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the install prompt");
          } else {
            console.log("User dismissed the install prompt");
            // Store the dismissal to prevent prompting again in the same session
            localStorage.setItem("installDismissed", "true");
          }
          this.$store.commit("setDeferredPrompt", null); // Reset the deferredPrompt
          this.setShowInstallPopup(false); // Hide the install popup after user interaction
        });
      } else {
        console.log("No install prompt available");
      }
    },

    dismissInstallPopup() {
      this.setShowInstallPopup(false);
      // Store the dismissal to prevent prompting again in the same session
      localStorage.setItem("installDismissed", "true");
    },
    async fetchUpdates() {
      try {
        const customerId = localStorage.getItem("tenantId"); // Replace with the actual CustomerId
        const base64Credentials = btoa("Ian:Ennistymon1!");

        const url = "https://live-api-v2.waywyser.com/api/VisitorCard/GetNews";
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            CustomerId: this.tenantId,
            Authorization: `Basic ${base64Credentials}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch updates.");
        }

        const updatesData = await response.json();
        this.updates = updatesData;
      } catch (error) {
        console.error("Error fetching updates:", error);
      }
    },
    async fetchRSS(blogUrl) {
      const apiUrl =
        "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fvisitwicklow.ie%2Fcategory%2Fnews%2Ffeed%2F&api_key=sxsemqxuwiupdstbyscdujlzdfb3szjo2top1pny";

      const response = await fetch(apiUrl);
      const data = await response.json();
      const items = data.items.slice(0, 3);

      for (let item of items) {
        const post = {
          title: item.title,
          link: item.link,
          image: await this.fetchImageFromPost(item.link),
        };
        this.posts.push(post);
      }
    },
    async fetchImageFromPost(url) {
      //const response = await fetch(
      //  `https://cors-anywhere.herokuapp.com/${url}`
      //  );
      // const text = await response.text();
      //  const parser = new DOMParser();
      //  const doc = parser.parseFromString(text, "text/html");
      //const imgElement = doc.querySelector('div[itemprop="image"] img');
      //return imgElement ? imgElement.src : "img/logo-text.svg";
      return "img/logo-text.svg";
    },
  },
};
</script>

<style>
.btn-large {
  padding: 20px 150px;
  font-size: 1.5rem;
}

/* General styles for the logo */
.logo {
  width: 100%;
  max-width: 350px;
  margin-bottom: 20px;
}

.btnMob {
  max-width: 250px;
  width: 100%;
  padding: 10px 20px;
  font-size: 1rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .logo {
    max-width: 300px; /* Adjust as needed for tablet-sized devices */
  }

  .btnMob {
    max-width: 150px; /* Adjust as needed for tablet-sized devices */
    padding: 8px 16px;
    font-size: 0.9rem;
    position: relative;
    top: -10px;
  }
}

@media (max-width: 576px) {
  .logo {
    max-width: 200px; /* Adjust as needed for mobile-sized devices */
  }

  .btnMob {
    max-width: 100px; /* Adjust as needed for mobile-sized devices */
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}

.update-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for a polished look */
  text-align: left;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.update-card:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* Deeper shadow on hover */
}

.update-title {
  font-size: 1.5rem;
  font-weight: 700; /* Bold title for emphasis */
  color: #333;
  margin-bottom: 5px;
}

.update-date {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 15px; /* Space between date and description */
  font-style: italic;
}

.update-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.6; /* Improved line spacing for readability */
}

.bg-white.mb-4.p-2.h-100 {
  padding: 0; /* Remove unnecessary padding here */
}

.container-fluid.py-5 {
  background-color: #f4f4f4; /* Light gray background for contrast */
}

.row > div {
  padding: 20px; /* Consistent padding around each card */
}

.install-popup {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  width: 95%; /* Nearly full screen width */
  max-width: 600px; /* Optional: limit the width on larger screens */
  text-align: center;
  z-index: 1000;
}

.install-button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #008cba;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-popup-button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #aaa;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
</style>
