import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import db from "./db";

// Function to inject the manifest
function injectDynamicManifest(manifest) {
  const manifestElement = document.createElement("link");
  manifestElement.rel = "manifest";

  const manifestBlob = new Blob([JSON.stringify(manifest)], {
    type: "application/manifest+json",
  });
  //manifestElement.href = URL.createObjectURL(manifestBlob);
  // Append a version or timestamp to the URL to prevent caching
  manifestElement.href = `${manifestURL}#${new Date().getTime()}`;

  document.head.appendChild(manifestElement);

  // Verify the manifest is injected
  console.log(
    "Manifest injected:",
    document.querySelector('link[rel="manifest"]')
  );
}

const tenantId = getTenantId();
//const manifest = getManifestForTenant(tenantId);
//injectDynamicManifest(manifest);

const installedTenantId = localStorage.getItem("tenantId");
if (installedTenantId != tenantId) {
  //domain has Changed, delete database and start over
  deleteDatabase();
  clearLocalStorage();
}

store.commit("setApiUrl", "https://live-api-v2.waywyser.com/api/");
store.commit("setCustomerId", tenantId);
localStorage.setItem("tenantId", tenantId);
const app = createApp(App).use(store).use(router);

let deferredPrompt = null;

function isAppInstalled() {
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone === true // iOS Safari standalone detection
  );
}

window.addEventListener("beforeinstallprompt", e => {
  e.preventDefault();
  deferredPrompt = e;

  // Store deferredPrompt in the Vuex store for use in components
  store.commit("setDeferredPrompt", deferredPrompt);

  // Show the install button or popup if the app is not already installed
  if (!isAppInstalled()) {
    store.commit("setShowInstallPopup", true);
  }
});

// Add event listener for appinstalled event
window.addEventListener("appinstalled", () => {
  console.log("App was installed");
  // Hide install prompts if the app was installed
  store.commit("setShowInstallPopup", false);
  localStorage.removeItem("installDismissed");
});

// Detect if the user is on an iPhone/iPad and not using the standalone app
if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
  if (!window.navigator.standalone && !isAppInstalled()) {
    // Show the install popup for iOS devices
    store.commit("setShowInstallPopup", true);
    // Since there is no deferredPrompt on iOS, set it to null or handle accordingly
    store.commit("setDeferredPrompt", true); // No deferred prompt for iOS
  }
}

// Utility function to check if the user is on a mobile device
function isMobileDevice() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

app.mount("#app");

console.log(store.state.apiUrl);
getHeaderData();

async function getHeaderData() {
  const url = store.state.apiUrl + "VisitorCard/GetHeaders";
  const base64Credentials = btoa("Ian:Ennistymon1!");
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        CustomerId: tenantId,
        Authorization: `Basic ${base64Credentials}`,
      },
    });
    console.log(response.status);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const jsonData = await response.json();
    // Clear the existing records in the 'header' store
    await db.header.clear();
    await db.header.add(jsonData);
  } catch (error) {
    console.error("Failed to load and insert data:", error);
  }
}

function getTenantId() {
  const hostname = window.location.hostname;

  // Default tenantId
  let tenantId = 174;

  // Check for localhost explicitly
  if (hostname === "localhost") {
    return tenantId;
  }

  // Extract the subdomain from the hostname
  const subdomain = hostname.split(".")[0];

  // Map subdomain to tenantId
  switch (subdomain) {
    case "wicklow":
      tenantId = 174;
      break;
    case "ennistymon":
      tenantId = 41; //really duhallow
      break;
    case "duhallow":
      tenantId = 41; //really duhallow
      break;
    default:
      tenantId = 174; // or handle the default case as needed
  }

  return tenantId;
}

function getManifestForTenant(tenantId) {
  // Customize icons and other settings based on the tenantId
  let icons;
  let name;
  switch (tenantId) {
    case 174:
      name = "Wicklow";
      icons = [
        {
          src: "img/icons/wicklow_192.png",
          sizes: "192x192",
          type: "image/png",
        },
        {
          src: "img/icons/wicklow_512.png",
          sizes: "512x512",
          type: "image/png",
        },
      ];
      break;
    case 41:
      name = "Duhallow";
      icons = [
        {
          src: "img/icons/duhallow_192.png",
          sizes: "192x192",
          type: "image/png",
        },
        {
          src: "img/icons/duhallow_512.png",
          sizes: "512x512",
          type: "image/png",
        },
      ];
      break;
    default:
      name = "Unknown";
      icons = [
        {
          src: "img/icons/default_192.png",
          sizes: "192x192",
          type: "image/png",
        },
        {
          src: "img/icons/default_512.png",
          sizes: "512x512",
          type: "image/png",
        },
      ];
      break;
  }

  return {
    name: name,
    short_name: name,
    start_url: ".",
    display: "standalone",
    background_color: "#ffffff",
    theme_color: "#4DBA87",
    icons: icons,
  };
}

function deleteDatabase() {
  const dbName = "AppDatabase";

  const request = indexedDB.deleteDatabase(dbName);

  request.onsuccess = () => {
    console.log("Database deleted successfully");
    redirectHome();
  };

  request.onerror = event => {
    console.error("Error deleting database:", event);
  };

  request.onblocked = () => {
    console.warn("Database deletion blocked");
  };
}

function clearLocalStorage() {
  localStorage.clear();
}
