<!-- src/views/CesiumMapView.vue -->
<template>
  <CesiumMap :id="CardId" />
</template>

<script>
import CesiumMap from "@/components/CesiumMap.vue";

export default {
  name: "CesiumMapView",
  components: {
    CesiumMap,
  },
  props: {
    CardId: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style></style>
