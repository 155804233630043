<template>
  <div class="container mt-5">
    <h2 class="mb-4">Reviews</h2>

    <div v-if="reviews && reviews.length" class="row">
      <div v-for="review in reviews" :key="review.Name" class="col-md-6 mb-4">
        <div class="text-center pb-4">
          <img
            class="img-fluid mx-auto"
            src="/img/testimonial-2.jpg"
            style="width: 100px; height: 100px"
          />
          <div class="testimonial-text bg-white p-4 mt-n5">
            <p class="mt-5">{{ review.Details }}</p>
            <h5 class="text-truncate">{{ review.Name }}</h5>
            <span>{{ review.Country }}</span>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="text-center">
      <p>No Reviews Yet, Why not leave one?</p>
    </div>

    <!-- Add Review Button -->
    <div class="text-center mt-4">
      <button class="btn btn-primary" @click="showModal = true">
        Add Review
      </button>
    </div>

    <!-- Review Modal -->
    <div v-if="showModal" class="modal fade show" style="display: block">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Your Review</h5>
            <button type="button" class="close" @click="closeModal">
              &times;
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitReview">
              <div class="form-group">
                <label for="name">Name:</label>
                <input
                  type="text"
                  v-model="newReview.Name"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label for="details">Details:</label>
                <textarea
                  v-model="newReview.Details"
                  class="form-control"
                  required
                ></textarea>
              </div>
              <div class="form-group">
                <label for="country">Country:</label>
                <input
                  type="text"
                  v-model="newReview.Country"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group text-center">
                <label for="starRating">Star Rating:</label>
                <star-rating
                  :rating="newReview.StarRating"
                  @rating-selected="setStarRating"
                  class="d-block mx-auto"
                ></star-rating>
              </div>
              <button type="submit" class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Background -->
    <div v-if="showModal" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import db from "@/db";
import StarRating from "@/components/StarRating.vue"; // Import the custom star rating component
import { mapState } from "vuex";

export default {
  name: "ReviewPage",
  components: {
    StarRating,
  },
  computed: {
    ...mapState(["apiUrl", "customerId"]),
  },
  props: ["CardId"],
  data() {
    return {
      reviews: [],
      showModal: false,
      newReview: {
        CustomerId: this.customerId, // Replace with actual customer ID if needed
        CardId: this.CardId,
        Name: "",
        Details: "",
        Country: "",
        StarRating: 1,
        IsActive: 2,
      },
    };
  },
  created() {
    this.fetchReviews();
  },
  methods: {
    async fetchReviews() {
      const card = await db.cards
        .where("CardId")
        .equals(parseInt(this.CardId))
        .first();
      if (card && card.Review) {
        this.reviews = card.Review;
      }
    },
    async submitReview() {
      const base64Credentials = btoa("Ian:Ennistymon1!");
      try {
        const response = await fetch(this.apiUrl + "review", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            CustomerId: this.customerId,
            Authorization: `Basic ${base64Credentials}`,
          },
          body: JSON.stringify(this.newReview),
        });

        if (response.ok) {
          this.reviews.push({ ...this.newReview });
          this.closeModal();
        } else {
          console.error("Failed to submit review:", response.statusText);
        }
      } catch (error) {
        console.error("Failed to submit review:", error);
      }
    },
    closeModal() {
      this.showModal = false;
      this.newReview = {
        CustomerId: this.customerId, // Replace with actual customer ID if needed
        CardId: this.CardId,
        Name: "",
        Details: "",
        Country: "",
        StarRating: 1,
        IsActive: 2,
      };
    },
    setStarRating(starRating) {
      this.newReview.StarRating = starRating;
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 960px;
}

/* Custom styles for modal */
.modal-backdrop {
  z-index: 1040;
}
.modal-content {
  z-index: 1050;
}
</style>
