<template>
  <div class="completed-walks">
    <h1>Completed Walks</h1>
    <div class="info-box">
      <h2>Info</h2>
      <p v-if="isRegistered">
        Your email <strong>{{ userEmail }}</strong> is registered for badges.
      </p>
      <p v-else>
        Your email is not registered for badges.
        <router-link to="/register/0">Register here</router-link>.
      </p>
      <p>
        This system tracks your walks and awards badges based on your
        activities. Make sure to register your email to start earning badges and
        enjoy the rewards!
      </p>
    </div>
    <div class="table-container">
      <table v-if="walks.length">
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="walk in walks" :key="walk.DateTime">
            <td>{{ walk.Name }}</td>
            <td>{{ formatDate(walk.DateTime) }}</td>
          </tr>
        </tbody>
      </table>
      <p v-else>No completed walks found.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompletedWalks",
  data() {
    return {
      walks: [],
      userEmail: "",
      isRegistered: false,
    };
  },
  created() {
    this.userEmail = localStorage.getItem("userEmail") || "";
    this.isRegistered = !!this.userEmail;
    this.fetchCompletedWalks();
  },
  methods: {
    async fetchCompletedWalks() {
      try {
        const customerId = localStorage.getItem("tenantId"); // Replace with the actual CustomerId
        const base64Credentials = btoa("Ian:Ennistymon1!");

        const response = await fetch(
          `https://live-api-v2.waywyser.com/api/VisitorCard/GetCompletedWalks?userId=${this.userEmail}&customerId=${customerId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              CustomerId: customerId,
              Authorization: `Basic ${base64Credentials}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch completed walks.");
        }

        const responseText = await response.text();
        if (!responseText) {
          console.warn("Empty response");
          return;
        }

        this.walks = JSON.parse(responseText);
      } catch (error) {
        console.error(error);
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return isNaN(date) ? "Invalid Date" : date.toLocaleDateString();
    },
  },
};
</script>

<style scoped>
.completed-walks {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.info-box,
.table-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

th,
td {
  border: 1px solid #ccc;
  padding: 12px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
  font-weight: bold;
}

td {
  background-color: #ffffff;
}

h1,
h2 {
  text-align: center;
  color: #333;
}

p {
  text-align: center;
  color: #666;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
