<template>
  <div class="register-form">
    <p>Would you like to register for reward badges and get free gifts?</p>
    <p>If so, please enter your email:</p>
    <input type="email" v-model="email" placeholder="Enter your email" />
    <div class="buttons">
      <button @click="saveEmail">SAVE</button>
      <button @click="noThanks">NO THANKS</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
    };
  },
  methods: {
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    saveEmail() {
      if (!this.email) {
        alert("Please enter an email address.");
      } else if (!this.validateEmail(this.email)) {
        alert("Please enter a valid email address.");
      } else {
        localStorage.setItem("userEmail", this.email);
        //alert("Email saved!");
        this.$router.go(-1); // Go back to the previous page
      }
    },
    noThanks() {
      this.$router.go(-1); // Go back to the previous page
    },
  },
};
</script>

<style scoped>
.register-form {
  border: 1px solid #000;
  padding: 20px;
  max-width: 300px;
  margin: 20px auto;
  text-align: center;
}

input {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

button {
  padding: 10px 20px;
  border: 1px solid #000;
  background: #fff;
  cursor: pointer;
}
</style>
