<template>
  <button class="btn btn-primary back-button" @click="goBack">← Back</button>
  <div>
    <div class="container-fluid">
      <div class="container-fluid py-5">
        <div class="chart-container" style="position: relative">
          <canvas id="elevationChart"></canvas>
        </div>
      </div>
    </div>

    <!-- Header End -->
  </div>
</template>

<script>
import db from "@/db";
import { Chart, registerables } from "chart.js";
import { resolveDirective } from "vue";
Chart.register(...registerables);

export default {
  async mounted() {
    try {
      // Fetch card data based on the CardId parameter
      const cardId = parseInt(this.$route.params.CardId); // Convert to integer if necessary
      var cardArray = await db.cards.where("CardId").equals(cardId).toArray();
      this.polyline = cardArray[0].Polyline;
    } catch (error) {
      console.error("Error fetching card data:", error);
    }

    this.createElevationChart();
  },
  data() {
    return {
      polyline: null,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Go back to the previous page
    },
    createElevationChart() {
      const ctx = document.getElementById("elevationChart").getContext("2d");

      const data = this.processPolylineData();

      const gradient = ctx.createLinearGradient(0, 0, 0, 800);

      // Add color stops for gradient transition
      gradient.addColorStop(0, "rgba(0, 100, 0, 1)"); // Dark green at the top
      gradient.addColorStop(1, "rgba(0, 255, 0, 1)"); // Light green at the bottom

      // Define chart with a plugin for the light blue background
      const chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: data.distances,
          datasets: [
            {
              label: "Elevation",

              borderColor: "rgba(255, 255, 255, 1)",
              borderWidth: 6,
              data: data.elevations,
              fill: true,
              backgroundColor: gradient,
              pointRadius: 0, //no dots on line
              tension: 0.3, //stright or curved line 0 -1
            },
          ],
        },
        options: {
          scales: {
            y: {
              grid: {
                display: false, // Hides the grid lines for the y-axis
              },
              title: {
                display: true,
                text: "Elevation (m)",
              },
            },
            x: {
              type: "linear", // Ensures the x-axis is treated as linear
              ticks: {
                stepSize: 500, // Display a tick every 500 meters
                // Callback to round the value and control display
                callback: function (value, index) {
                  // Round the value to the nearest whole number
                  const roundedValue = Math.round(value);
                  // Only display the tick if the rounded value modulo 500 is 0
                  return roundedValue % 500 === 0 ? roundedValue : "";
                },
              },
              grid: {
                display: false, // Hides the grid lines for the x-axis
              },
              title: {
                display: true,
                text: "Distance (m)",
              },
            },
          },
        },
      });

      // Now that the chart is created, update its datasets to ensure the fill is applied
      chart.update();
    },

    processPolylineData() {
      // Assuming each point in the polyline array has { lat, lng, distanceFromStart, height }
      const elevations = this.polyline.map(point => point.height);
      const distances = this.polyline.map(point => point.distanceFromStart);
      return { elevations, distances };
    },
  },
};
</script>
<style scoped>
.chart-container {
  position: relative;
  height: 600px; /* Adjust based on your needs */
  display: flex;
  justify-content: center; /* Centers children horizontally */
  align-items: center; /* Centers children vertically (optional) */
}
</style>
