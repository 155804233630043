import Dexie from "dexie";

const db = new Dexie("AppDatabase");

// Define the database schema and versioning
db.version(1).stores({
  cards:
    "++id, CardId, IsActive, CustomerId, AppId, Name, SubName, coverImage, elevationImageId, [CardId+CustomerId]",
  pois: "MarkerId, CustomerId, Title, SubTitle, Latitude, Longitude, ProximityDistanceMeters, imageId, Body, DirectionsLink, Website, Colour, Triggered, TypeId, Timings, DaysOpened, rootId",
  images: "++id, &imageUrl, blob",
  pdfs: "++id, CardId, imageId, Title",
});

// Add a new version for the issues and badges stores
db.version(2).stores({
  issues: "++id, cardId, description, photo, latitude, longitude, timestamp",
  badges:
    "++id, customerId, cardId, email, start, start1km, end1km, longitude, latitude, timestamp",
  header:
    "++id, CustomerId, Title, Subtitle, Blurb, Logo, MainImageCarousel, SecondFrontImage, BlogURL, Email, Phone, Address, Facebook, Twitter, LinkedIn, Instagram, Youtube",
});

// Add a new version for the completedTrails store with unique VisitorCardId
db.version(3).stores({
  completedTrails: "&VisitorCardId, emailaddress, datetime", // &VisitorCardId to ensure uniqueness
});

// Listen for errors during database initialization
db.open().catch(err => {
  console.error("Error initializing database:", err);
});

export default db;
