<template>
  <button class="btn btn-primary back-button" @click="goBack">← Back</button>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="col-lg-12">
        <h2>Points Of Interest</h2>
      </div>
    </div>

    <div class="container py-5" style="padding-top: 10px !important">
      <div class="row">
        <div class="col-lg-12">
          <input
            type="text"
            class="form-control mb-4"
            placeholder="Search POIs..."
            v-model="searchQuery"
          />

          <!-- Sort Button -->
          <button
            @click="toggleSortDirection"
            class="btn btn-outline-primary mb-4"
          >
            Sort by Distance
            <span v-if="sortDirection === 'asc'">↑</span>
            <span v-else>↓</span>
          </button>

          <div class="row pb-3">
            <div
              class="col-md-6 mb-4 pb-2"
              v-for="poi in filteredPOIs"
              :key="poi.MarkerId"
            >
              <router-link
                :to="'/point/' + poi.MarkerId"
                class="col-md-6 mb-4 pb-2"
                style="text-decoration: none; color: inherit"
              >
                <div class="blog-item">
                  <div class="position-relative">
                    <ImageComponent
                      v-if="poi.imageId"
                      :imageId="poi.imageId"
                    ></ImageComponent>
                  </div>
                  <div class="bg-white p-4">
                    <div class="d-flex mb-2">
                      <a
                        class="text-primary text-uppercase text-decoration-none"
                        href=""
                        >{{ poi.Title }}</a
                      >
                      <div class="distance-overlay">
                        ( {{ poi.DistanceFromCurrentLocation.toFixed(2) }} km)
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import db from "@/db"; // Adjust based on your project structure
import ImageComponent from "@/components/ImageComponent.vue";

export default {
  name: "POIsView",
  components: {
    ImageComponent,
  },
  props: {
    CardId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: "",
      pois: [],
      currentLat: null,
      currentLng: null,
      sortDirection: "asc",
    };
  },
  computed: {
    filteredPOIs() {
      let result = this.pois.map(poi => {
        const distance = this.getDistanceFromLatLonInKm(
          this.currentLat,
          this.currentLng,
          parseFloat(poi.Latitude),
          parseFloat(poi.Longitude)
        );
        return { ...poi, DistanceFromCurrentLocation: distance };
      });

      if (this.searchQuery) {
        result = result.filter(
          poi =>
            poi.Title.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            poi.Body.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }

      // Sort by distance, respecting the current sort direction
      result.sort((a, b) => {
        const sortMultiplier = this.sortDirection === "asc" ? 1 : -1;
        return (
          (a.DistanceFromCurrentLocation - b.DistanceFromCurrentLocation) *
          sortMultiplier
        );
      });

      return result;
    },
  },
  methods: {
    toggleSortDirection() {
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    },
    goBack() {
      this.$router.go(-1); // Go back to the previous page
    },
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the earth in kilometers
      const dLat = this.deg2rad(lat2 - lat1);
      const dLon = this.deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) *
          Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },

    async fetchPOIs() {
      try {
        const cardIdNumber = Number(this.CardId);
        if (isNaN(cardIdNumber)) {
          console.error("Invalid CardId:", this.CardId);
          return;
        }
        const card = await db.cards
          .where("CardId")
          .equals(cardIdNumber)
          .first();

        if (card && card.POI) {
          var test = card.POI;
          this.pois = card.POI.filter(poi => poi.TypeId === "1");
        } else {
          console.error("No POIs found for this CardId");
        }
      } catch (error) {
        console.error("Error fetching POIs:", error);
      }
    },
    getCurrentLocationAndFetchPOIs() {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.currentLat = position.coords.latitude;
          this.currentLng = position.coords.longitude;
          //this.fetchPOIs(); // Fetch POIs after getting current location
        },
        error => {
          console.error("Geolocation error:", error);
          //this.fetchPOIs(); // Still fetch POIs even if geolocation fails
        }
      );
      this.fetchPOIs();
    },
  },
  mounted() {
    this.getCurrentLocationAndFetchPOIs();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getCurrentLocationAndFetchPOIs();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.getCurrentLocationAndFetchPOIs();
    next();
  },
  watch: {
    "$route.params.CardId": {
      immediate: true,
      handler() {
        this.getCurrentLocationAndFetchPOIs();
      },
    },
  },
  activated() {
    this.getCurrentLocationAndFetchPOIs();
  },
};
</script>

<style scoped>
.blog-item img {
  width: 540px;
  height: 360px;
  object-fit: cover; /* This will cover the area, cropping the image as needed. Use 'contain' to fit the whole image without cropping */
}
</style>
