<template>
  <button class="btn btn-primary back-button" @click="goBack">← Back</button>
  <!-- Header Start -->
  <div class="container-fluid" :style="headerBackgroundStyle">
    <div class="container">
      <ImageComponent
        v-if="card && card.CoverImage"
        :imageId="card.CoverImage"
        @image-fetched="setHeaderBackgroundImage"
        :asBackground="true"
      />
      <div
        class="d-flex flex-column align-items-center justify-content-center"
        style="min-height: 400px"
      >
        <h6 v-if="card" class="display-4 text-white text-uppercase">
          {{ card.Name }}
        </h6>
      </div>
    </div>
  </div>
  <!-- Header End -->

  <!-- Service Start -->
  <div class="container-fluid py-5">
    <div class="container pb-3">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div
            class="buttonsx"
            style="position: Relative; top: -20px; font-size: 11px"
          >
            <buttonx :class="{ active: registered }" @click="registerForBadges">
              <span v-html="firstButtonText"></span>
            </buttonx>

            <buttonx
              :class="{ active: !registered }"
              @click="unRegisterForBadges"
            >
              {{ secondButtonText }}
            </buttonx>
            <button
              type="button"
              class="btn btn-secondary"
              data-toggle="popover"
              data-trigger="focus"
              title="Information"
              data-html="true"
              data-content="As an outdoor recreation incentive, Register to claim points and reclaim for free gifts. see <a href='https://www.wicklowWebsiteHere.com' target='_blank'>wicklowWebsiteHere.com</a> for details"
            >
              <i class="fa fa-question-circle"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 mb-4">
          <router-link
            v-if="card"
            :to="'/information/' + card.CardId"
            style="text-decoration: none; color: inherit"
          >
            <div class="service-item bg-white text-center mb-2 py-5 px-4">
              <i class="fa fa-2x fa-question-circle mx-auto mb-4"></i>
              <h5 class="mb-2">INFORMATION</h5>
              <p class="m-0">Get Information about {{ card.Name }}</p>
            </div>
          </router-link>
        </div>

        <div class="col-lg-4 col-md-6 mb-4">
          <div
            class="service-item bg-white text-center mb-2 py-5 px-4"
            @click="
              openGoogleMaps(card.StartLocationLat, card.StartLocationLng)
            "
            style="cursor: pointer"
          >
            <i class="fa fa-2x fa-route mx-auto mb-4"></i>
            <h5 class="mb-2">TRAIL HEAD</h5>
            <p class="m-0">Open in Google Maps</p>
            <br />
          </div>
        </div>

        <div class="col-lg-4 col-md-6 mb-4">
          <router-link
            v-if="card"
            :to="'/map/' + card.CardId"
            style="text-decoration: none; color: inherit"
          >
            <div class="service-item bg-white text-center mb-2 py-5 px-4">
              <i class="fa fa-2x fa-play mx-auto mb-4"></i>
              <h5 class="mb-2">START NOW</h5>
              <p class="m-0">
                When at the trail head, Click here to log your activity
              </p>
            </div>
          </router-link>
        </div>
        <!--
        <div class="col-lg-4 col-md-6 mb-4">
          <router-link
            v-if="card"
            :to="'/gallery/' + card.CardId"
            style="text-decoration: none; color: inherit"
          >
            <div class="service-item bg-white text-center mb-2 py-5 px-4">
              <i class="fa fa-2x fa-camera mx-auto mb-4"></i>
              <h5 class="mb-2">GALLERY</h5>
              <p class="m-0">Explore Imagery of {{ card.Name }}</p>
            </div>
          </router-link>
        </div>
        -->
        <div class="col-lg-4 col-md-6 mb-4">
          <router-link
            v-if="card"
            :to="'/elevation/' + card.CardId"
            style="text-decoration: none; color: inherit"
          >
            <div class="service-item bg-white text-center mb-2 py-5 px-4">
              <i class="fa fa-2x fa-chart-area mx-auto mb-4"></i>
              <h5 class="mb-2">ROUTE ASCENT PROFILE</h5>
              <p class="m-0">
                See the elevation profile of {{ card.Name }} walk
              </p>
            </div>
          </router-link>
        </div>

        <!--
        <div class="col-lg-4 col-md-6 mb-4">
          <router-link
            v-if="card"
            :to="'/reviews/' + card.CardId"
            style="text-decoration: none; color: inherit"
          >
            <div class="service-item bg-white text-center mb-2 py-5 px-4">
              <i class="fa fa-2x fa-comments mx-auto mb-4"></i>
              <h5 class="mb-2">REVIEWS</h5>
              <p class="m-0">See what others think of {{ card.Name }}</p>
            </div>
          </router-link>
        </div>
-->

        <div class="col-lg-4 col-md-6 mb-4">
          <router-link
            v-if="card"
            :to="'/services/' + card.CardId"
            style="text-decoration: none; color: inherit"
          >
            <div class="service-item bg-white text-center mb-2 py-5 px-4">
              <i class="fa fa-2x fa-concierge-bell mx-auto mb-4"></i>
              <h5 class="mb-2">NEAR BY SERVICES</h5>
              <p class="m-0">Services Near {{ card.Name }}</p>
            </div>
          </router-link>
        </div>
        <div class="col-lg-4 col-md-6 mb-4">
          <router-link
            v-if="card"
            :to="'/pois/' + card.CardId"
            style="text-decoration: none; color: inherit"
          >
            <div class="service-item bg-white text-center mb-2 py-5 px-4">
              <i class="fa fa-2x fa-map-marker mx-auto mb-4"></i>

              <h5 class="mb-2">NEAR BY POIs</h5>
              <p class="m-0">Points Of Interest Near {{ card.Name }}</p>
            </div>
          </router-link>
        </div>

        <div class="col-lg-4 col-md-6 mb-4" v-if="card && card.VideoLink">
          <router-link
            v-if="card"
            :to="'/video/' + card.CardId"
            style="text-decoration: none; color: inherit"
          >
            <div class="service-item bg-white text-center mb-2 py-5 px-4">
              <i class="fa fa-2x fa-map-marker mx-auto mb-4"></i>

              <h5 class="mb-2">Video Overview</h5>
              <p class="m-0">See a video of {{ card.Name }}</p>
            </div>
          </router-link>
        </div>

        <div class="col-lg-4 col-md-6 mb-4">
          <router-link
            v-if="card"
            :to="'/issue/' + card.CardId"
            style="text-decoration: none; color: inherit"
          >
            <div class="service-item bg-white text-center mb-2 py-5 px-4">
              <i class="fa fa-2x fa-map-marker mx-auto mb-4"></i>

              <h5 class="mb-2">Report Issue</h5>
              <p class="m-0">Send us the details</p>
            </div>
          </router-link>
        </div>

        <!--
        <div class="col-lg-4 col-md-6 mb-4">
          <router-link
            v-if="card"
            :to="'/cesium-map/' + card.CardId"
            style="text-decoration: none; color: inherit"
          >
            <div class="service-item bg-white text-center mb-2 py-5 px-4">
              <i class="fa fa-2x fa-globe mx-auto mb-4"></i>
              <h5 class="mb-2">3D VIEW</h5>
              <p class="m-0">3d View of the terrain around {{ card.Name }}</p>
            </div>
          </router-link>
        </div>
        -->
      </div>
    </div>
  </div>
  <!-- Service End -->
</template>

<script>
import db from "@/db";
import ImageComponent from "@/components/ImageComponent.vue";

export default {
  components: {
    ImageComponent,
  },
  computed: {
    firstButtonText() {
      return this.registered
        ? "Registered for Badges  <i class='fa fa-check'></i>"
        : "Register for Badges";
    },
    secondButtonText() {
      return this.registered
        ? "Unregister for Badges"
        : "UnRegistered for Badges";
    },
    headerBackgroundStyle() {
      if (this.headerImageUrl) {
        return {
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${this.headerImageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        };
      }
      return {};
    },
  },
  data() {
    return {
      card: null,
      headerImageUrl: "",
      registered: false,
    };
  },
  created() {
    this.checkRegistrationStatus();
  },
  async mounted() {
    try {
      // Fetch card data based on the CardId parameter
      const cardId = parseInt(this.$route.params.CardId); // Convert to integer if necessary
      var cardArray = await db.cards.where("CardId").equals(cardId).toArray();
      this.card = cardArray[0];
      console.log(this.card.Name);
    } catch (error) {
      console.error("Error fetching card data:", error);
    }

    // Initialize popovers
    $(function () {
      $('[data-toggle="popover"]').popover();
    });
  },
  methods: {
    checkRegistrationStatus() {
      this.registered = !!localStorage.getItem("userEmail");
    },
    registerForBadges() {
      this.$router.push({
        name: "register",
        params: { CardId: this.card.CardId },
      });
    },
    unRegisterForBadges() {
      localStorage.removeItem("userEmail");
      this.registered = !!localStorage.getItem("userEmail");
    },
    goBack() {
      this.$router.go(-1);
    },
    setHeaderBackgroundImage(imageUrl) {
      this.headerImageUrl = imageUrl;
    },
    openGoogleMaps(lat, lng) {
      // Construct Google Maps URL
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

      // Open Google Maps URL
      window.open(googleMapsUrl, "_blank");
    },
  },
};
</script>
<style scoped>
h6 {
  font-size: 30px;
}

.buttonsx {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  gap: 1px;
}

buttonx {
  padding: 10px 20px;
  border: 1px solid #000;
  background: #fff;
  cursor: pointer;
  width: 300px;
}

buttonx.active {
  background: rgb(3, 167, 3);
  color: #fff;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  opacity: 1;
}

.help-icon {
  font-size: 24px;
  cursor: pointer;
  top: 10px;
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #838181;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 50%;
  margin-left: -250px;
  opacity: 1 !important;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
