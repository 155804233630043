<template>
  <router-view />
  <GlobalProgress />
  <DataComponent v-if="!$store.getters.isDataLoaded" />
</template>
<script>
import DataComponent from "./components/Data.vue";
import GlobalProgress from "./components/GlobalProgress.vue";

export default {
  name: "App",
  components: {
    DataComponent,
    GlobalProgress,
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
