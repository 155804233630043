<template>
  <button class="btn btn-primary back-button" @click="goBack">← Back</button>

  <div class="container">
    <h2>Report an Issue</h2>
    <div class="form-group">
      <label for="issueDescription" class="form-label"
        >Describe the issue observed:</label
      >
      <textarea
        id="issueDescription"
        class="form-control"
        v-model="issueDescription"
        rows="5"
        placeholder="Describe the issue observed..."
      ></textarea>
    </div>

    <div class="form-group">
      <label for="photoUpload" class="form-label"
        >Upload photo from phone:</label
      >
      <div class="custom-file">
        <input
          type="file"
          id="photoUpload"
          class="custom-file-input"
          @change="handleFileUpload"
        />
        <label class="custom-file-label" for="photoUpload"
          >Choose file...</label
        >
      </div>
    </div>

    <div class="form-group mt-3">
      <p>
        If you are still at the location of the issue, push the button below to
        include your location.
      </p>
      <button class="btn btn-primary" @click="captureLocation">
        Capture current location
      </button>
      <div v-if="locationCaptured" class="mt-2">
        <p>
          Location captured: {{ currentLocation.latitude }},
          {{ currentLocation.longitude }}
        </p>
      </div>
    </div>

    <button id="btnSave" class="btn btn-success mt-8" @click="saveIssue">
      Save
    </button>
  </div>
</template>

<script>
import db from "@/db"; // Adjust the path to your IndexedDB setup

export default {
  data() {
    return {
      issueDescription: "",
      photo: null,
      currentLocation: null,
      locationCaptured: false,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Go back to the previous page
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.photo = file;
      }
    },
    captureLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.currentLocation = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            this.locationCaptured = true;
          },
          error => {
            console.error("Geolocation error:", error);
          }
        );
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    },
    async saveIssue() {
      const issueData = {
        cardId: parseInt(this.$route.params.CardId),
        description: this.issueDescription,
        photo: this.photo,
        latitude: this.currentLocation?.latitude,
        longitude: this.currentLocation?.longitude,
        timestamp: new Date().toISOString(),
      };

      try {
        // Try sending the issue data to the REST API
        await this.sendIssueToAPI(issueData);
        alert(
          "Thank you for your feedback, we will look into this issue right away."
        );
      } catch (error) {
        console.error("Error sending issue to API:", error);
        // Save to IndexedDB if the API call fails
        try {
          await db.issues.add(issueData);
          this.registerBackgroundSync();
          alert("Issue saved locally and will be synced later.");
        } catch (dbError) {
          console.error("Error saving issue to IndexedDB:", dbError);
        }
      }
    },
    async sendIssueToAPI(issueData) {
      const formData = new FormData();
      formData.append("description", issueData.description);
      if (issueData.photo) {
        formData.append("photo", issueData.photo);
      }
      if (issueData.latitude && issueData.longitude) {
        formData.append("latitude", issueData.latitude);
        formData.append("longitude", issueData.longitude);
      }
      formData.append("VisitorCardId", issueData.cardId);
      formData.append("timestamp", issueData.timestamp);

      const base64Credentials = btoa("Ian:Ennistymon1!");
      const response = await fetch(
        //"http://localhost:10775/api/VisitorCard/ReportIssue",
        "https://live-api-v2.waywyser.com/api/VisitorCard/ReportIssue",
        {
          method: "POST",
          headers: {
            CustomerId: localStorage.getItem("tenantId"), // Replace with actual CustomerId
            Authorization: `Basic ${base64Credentials}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send issue to API");
      }
    },
    async registerBackgroundSync() {
      if ("serviceWorker" in navigator && "SyncManager" in window) {
        try {
          const registration = await navigator.serviceWorker.ready;
          await registration.sync.register("sync-issues");
        } catch (error) {
          console.error("Error registering background sync:", error);
        }
      } else {
        console.warn("Background sync is not supported in this browser.");
      }
    },
  },
};
</script>

<style scoped>
.container {
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.custom-file-input {
  display: none;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 2.5rem;
  margin-bottom: 0;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.btn-primary,
.btn-success {
  margin-top: 20px;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

#btnSave {
  width: 300px;
  height: 60px;
  font-size: 24px;
}
</style>
