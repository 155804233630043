<template>
  <!-- Header Start -->
  <div class="container-fluid page-header">
    <div class="container">
      <div
        class="d-flex flex-column align-items-center justify-content-center"
        style="min-height: 400px"
      >
        <h6 v-if="card" class="display-4 text-white text-uppercase">
          {{ card.Name }}
        </h6>
      </div>
    </div>
  </div>
  <!-- Header End -->

  <!-- Service Start -->
  <div class="container-fluid py-5">
    <button
      class="btn btn-primary back-button"
      @click="goBack"
      style="float: left"
    >
      ← Back
    </button>
    <div class="container pt-5 pb-3">
      <div class="row">
        <div v-if="card && card.Gallery" class="row">
          <!-- Loop through the card's gallery array -->
          <div
            v-for="(image, index) in card.Gallery"
            :key="index"
            class="col-lg-4 col-md-6 mb-4"
          >
            <div class="package-item bg-white mb-2">
              <!-- Use image.Url for the src attribute -->
              <img
                class="img-fluid"
                :src="image.imageData.imageUrl"
                :alt="image.Title"
                @click="showLightbox(index)"
              />
              <div class="p-4">
                <!-- Use image.Title for the link text -->
                <a class="h5 text-decoration-none" href="">{{ image.Title }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-easy-lightbox
      :visible="visible"
      :imgs="images"
      :index="currentIndex"
      @hide="visible = false"
    />
  </div>
  <!-- Service End -->
</template>
<script>
import db from "@/db";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      card: null,
      visible: false,
      currentIndex: 0,
      images: [],
    };
  },
  async mounted() {
    try {
      // Fetch card data based on the CardId parameter
      const cardId = parseInt(this.$route.params.CardId); // Convert to integer if necessary

      //only fetch the Gallery data rather than the whl;e card
      var galleryData = await db.cards
        .where("CardId")
        .equals(cardId)
        .toArray()
        .then(cards => cards[0].Gallery);

      // Fetch images for each imageId asynchronously
      const imagesPromises = galleryData.map(async galleryItem => {
        const imageId = galleryItem.imageId; // Assuming imageId is the key to fetch images
        const imageData = await db.images.get(imageId); // Fetch image data
        return { ...galleryItem, imageData }; // Merge image data with gallery item
      });

      // Wait for all image fetching promises to resolve
      const galleryWithImages = await Promise.all(imagesPromises);

      galleryWithImages.forEach(galleryItem => {
        // Assuming galleryItem.ImageData.blob contains the image blob data
        galleryItem.imageData.imageUrl = URL.createObjectURL(
          galleryItem.imageData.blob
        ); // Create URL from blob
      });

      // Update card data with gallery containing image data
      this.card = { Gallery: galleryWithImages };
      this.images = galleryWithImages.map(item => item.imageData.imageUrl); // Store the URLs for the lightbox
    } catch (error) {
      console.error("Error fetching card data:", error);
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Go back to the previous page
    },
    showLightbox(index) {
      this.currentIndex = index;
      this.visible = true;
    },
  },
};
</script>
<style scoped>
.package-item.bg-white.mb-2 {
  padding: 10px;
}
</style>
