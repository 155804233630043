// store.js
import { createStore } from "vuex";

export default createStore({
  state: {
    dataLoaded: false,
    progress: 0,
    progressMessage: "",
    isLoading: false,
    apiUrl: "",
    customerId: null,
    version: null,
    deferredPrompt: null,
    showInstallPopup: false,
  },
  getters: {
    isDataLoaded: state => state.dataLoaded,
  },
  mutations: {
    setDeferredPrompt(state, prompt) {
      state.deferredPrompt = prompt;
    },
    setShowInstallPopup(state, value) {
      state.showInstallPopup = value;
    },
    setDataLoaded(state, value) {
      state.dataLoaded = value;
    },
    setProgress(state, progress) {
      state.progress = progress;
    },
    setProgressMessage(state, message) {
      state.progressMessage = message;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setApiUrl(state, url) {
      // Add mutation for apiUrl
      state.apiUrl = url;
    },
    setCustomerId(state, id) {
      // Add mutation for customerId
      state.customerId = id;
    },
  },
  actions: {
    async loadData({ commit, state }) {
      if (!state.dataLoaded) {
        // Your data loading logic here...
        // For example, this could call insertData from your component
        // Once data is loaded:
        commit("setDataLoaded", true);
      }
    },
    updateProgress({ commit }, { progress, message }) {
      commit("setProgress", progress);
      commit("setProgressMessage", message);
      commit("setLoading", progress < 100);
    },
  },
  modules: {
    // your modules
  },
});
