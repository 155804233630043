<template>
  <div class="map-container">
    <l-map :zoom="13" :center="mapCenter" style="height: 350px">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-marker :lat-lng="startLatLng" :icon="startIcon"></l-marker>
      <l-marker :lat-lng="endLatLng" :icon="endIcon"></l-marker>
      <l-polyline :lat-lngs="polylinePath" color="red" weight="5"></l-polyline>
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPolyline } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

export default {
  props: {
    polyline: Array,
    StartLocationLat: String,
    StartLocationLng: String,
    EndLocationLat: String,
    EndLocationLng: String,
  },

  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
  },
  mounted() {
    // Now that the component is mounted, L is available to use.
    this.startIcon = L.icon({
      iconUrl: require("@/assets/start.png"),
      iconSize: [40, 40],
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
    });
    this.endIcon = L.icon({
      iconUrl: require("@/assets/stop.png"),
      iconSize: [40, 40],
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
    });
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
      startIcon: null,
      endIcon: null,
    };
  },
  computed: {
    mapCenter() {
      return [
        parseFloat(this.StartLocationLat),
        parseFloat(this.StartLocationLng),
      ];
    },
    startLatLng() {
      return [
        //parseFloat(this.StartLocationLat),
        // parseFloat(this.StartLocationLng),
        parseFloat(this.polyline[0][0]),
        parseFloat(this.polyline[0][1]),
      ];
    },
    endLatLng() {
      return [
        parseFloat(parseFloat(this.polyline[this.polyline.length - 1][0])),
        parseFloat(parseFloat(this.polyline[this.polyline.length - 1][1])),
      ];
    },
    polylinePath() {
      return this.polyline.map(point => [
        parseFloat(point.lat),
        parseFloat(point.lng),
      ]);
    },
  },
};
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 400px; /* Set the height of the map */
}
</style>
