<template>
  <img
    v-if="imageUrl"
    :src="imageUrl"
    class="w-100 h-100"
    alt=""
    style="object-fit: cover; z-index: -1; left: -50px"
    @click="emitImageClicked"
  />
</template>

<script>
import db from "@/db";
export default {
  props: {
    imageId: {
      type: [Number, String],
      required: true,
      default: () => undefined,
    },
  },
  data() {
    return {
      imageUrl: "",
    };
  },
  async mounted() {
    if (this.imageId) {
      await this.fetchAndSetImageUrl();
    } else {
      console.warn("imageId prop is undefined");
    }
  },
  methods: {
    async fetchImageUrl(imageId) {
      console.log(imageId);
      // Placeholder for your actual fetchImageUrl implementation
      if (!imageId) return "";
      try {
        const imageRecord = await db.images.get(imageId);
        return imageRecord ? URL.createObjectURL(imageRecord.blob) : "";
      } catch (error) {
        console.error("Failed to fetch image:", error);
        return "";
      }
    },
    async fetchAndSetImageUrl() {
      this.imageUrl = await this.fetchImageUrl(this.imageId);
      this.$emit("image-fetched", this.imageUrl); // Emit the fetched URL to the parent
    },
    async emitImageFetched() {
      this.$emit("image-clicked", this.imageUrl); // Emit the clicked URL to the parent
    },
  },
  beforeDestroy() {
    if (this.imageUrl) {
      URL.revokeObjectURL(this.imageUrl);
    }
  },
};
</script>
